export const Notification = () => import('../../components/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const Warning = () => import('../../components/Warning.vue' /* webpackChunkName: "components/warning" */).then(c => wrapFunctional(c.default || c))
export const BaseCard = () => import('../../components/base/Card.vue' /* webpackChunkName: "components/base-card" */).then(c => wrapFunctional(c.default || c))
export const CoreAppBar = () => import('../../components/core/AppBar.vue' /* webpackChunkName: "components/core-app-bar" */).then(c => wrapFunctional(c.default || c))
export const CoreDrawer = () => import('../../components/core/Drawer.vue' /* webpackChunkName: "components/core-drawer" */).then(c => wrapFunctional(c.default || c))
export const CoreFooter = () => import('../../components/core/Footer.vue' /* webpackChunkName: "components/core-footer" */).then(c => wrapFunctional(c.default || c))
export const CoreView = () => import('../../components/core/View.vue' /* webpackChunkName: "components/core-view" */).then(c => wrapFunctional(c.default || c))
export const MaterialNotification = () => import('../../components/material/Notification.vue' /* webpackChunkName: "components/material-notification" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
