import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d8a8f054 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _d9025900 = () => interopDefault(import('../pages/contacts/index.vue' /* webpackChunkName: "pages/contacts/index" */))
const _2a41789a = () => interopDefault(import('../pages/investorinfo/index.vue' /* webpackChunkName: "pages/investorinfo/index" */))
const _1a4d2aa6 = () => interopDefault(import('../pages/jobpost/index.vue' /* webpackChunkName: "pages/jobpost/index" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _076ad200 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _d19c4626 = () => interopDefault(import('../pages/qna/index.vue' /* webpackChunkName: "pages/qna/index" */))
const _e9039c6a = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _6240db34 = () => interopDefault(import('../pages/sitepolicy/index.vue' /* webpackChunkName: "pages/sitepolicy/index" */))
const _4c6f6a54 = () => interopDefault(import('../pages/sustainability/index.vue' /* webpackChunkName: "pages/sustainability/index" */))
const _7a848725 = () => interopDefault(import('../pages/teammembers/index.vue' /* webpackChunkName: "pages/teammembers/index" */))
const _2b59884f = () => interopDefault(import('../pages/about/companyInfo.vue' /* webpackChunkName: "pages/about/companyInfo" */))
const _0979bda8 = () => interopDefault(import('../pages/about/create.vue' /* webpackChunkName: "pages/about/create" */))
const _040fe73e = () => interopDefault(import('../pages/contacts/create.vue' /* webpackChunkName: "pages/contacts/create" */))
const _ce85052a = () => interopDefault(import('../pages/investorinfo/create.vue' /* webpackChunkName: "pages/investorinfo/create" */))
const _570c74d8 = () => interopDefault(import('../pages/jobpost/create.vue' /* webpackChunkName: "pages/jobpost/create" */))
const _9686d884 = () => interopDefault(import('../pages/news/create.vue' /* webpackChunkName: "pages/news/create" */))
const _76be0b71 = () => interopDefault(import('../pages/qna/create.vue' /* webpackChunkName: "pages/qna/create" */))
const _0bfc5253 = () => interopDefault(import('../pages/services/create.vue' /* webpackChunkName: "pages/services/create" */))
const _69322aea = () => interopDefault(import('../pages/sustainability/create.vue' /* webpackChunkName: "pages/sustainability/create" */))
const _047eaf8e = () => interopDefault(import('../pages/teammembers/create.vue' /* webpackChunkName: "pages/teammembers/create" */))
const _121085fc = () => interopDefault(import('../pages/about/delete/_id.vue' /* webpackChunkName: "pages/about/delete/_id" */))
const _75964b61 = () => interopDefault(import('../pages/about/edit/_id.vue' /* webpackChunkName: "pages/about/edit/_id" */))
const _00932b98 = () => interopDefault(import('../pages/contacts/delete/_id.vue' /* webpackChunkName: "pages/contacts/delete/_id" */))
const _23486777 = () => interopDefault(import('../pages/contacts/edit/_id.vue' /* webpackChunkName: "pages/contacts/edit/_id" */))
const _8a003976 = () => interopDefault(import('../pages/investorinfo/delete/_id.vue' /* webpackChunkName: "pages/investorinfo/delete/_id" */))
const _42e92664 = () => interopDefault(import('../pages/investorinfo/edit/_id.vue' /* webpackChunkName: "pages/investorinfo/edit/_id" */))
const _40fb5c32 = () => interopDefault(import('../pages/jobpost/delete/_id.vue' /* webpackChunkName: "pages/jobpost/delete/_id" */))
const _ad5012de = () => interopDefault(import('../pages/jobpost/edit/_id.vue' /* webpackChunkName: "pages/jobpost/edit/_id" */))
const _0bd4cfd0 = () => interopDefault(import('../pages/news/delete/_id.vue' /* webpackChunkName: "pages/news/delete/_id" */))
const _5b7bf3f7 = () => interopDefault(import('../pages/news/edit/_id.vue' /* webpackChunkName: "pages/news/edit/_id" */))
const _6a5dfe4b = () => interopDefault(import('../pages/qna/delete/_id.vue' /* webpackChunkName: "pages/qna/delete/_id" */))
const _ba036a2c = () => interopDefault(import('../pages/qna/edit/_id.vue' /* webpackChunkName: "pages/qna/edit/_id" */))
const _177a042d = () => interopDefault(import('../pages/services/delete/_id.vue' /* webpackChunkName: "pages/services/delete/_id" */))
const _3c733d68 = () => interopDefault(import('../pages/services/edit/_id.vue' /* webpackChunkName: "pages/services/edit/_id" */))
const _83588d78 = () => interopDefault(import('../pages/sustainability/delete/_id.vue' /* webpackChunkName: "pages/sustainability/delete/_id" */))
const _6e2f1fba = () => interopDefault(import('../pages/sustainability/edit/_id.vue' /* webpackChunkName: "pages/sustainability/edit/_id" */))
const _41b4f713 = () => interopDefault(import('../pages/teammembers/delete/_id.vue' /* webpackChunkName: "pages/teammembers/delete/_id" */))
const _73ccd5b2 = () => interopDefault(import('../pages/teammembers/edit/_id.vue' /* webpackChunkName: "pages/teammembers/edit/_id" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _d8a8f054,
    name: "about"
  }, {
    path: "/contacts",
    component: _d9025900,
    name: "contacts"
  }, {
    path: "/investorinfo",
    component: _2a41789a,
    name: "investorinfo"
  }, {
    path: "/jobpost",
    component: _1a4d2aa6,
    name: "jobpost"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/news",
    component: _076ad200,
    name: "news"
  }, {
    path: "/qna",
    component: _d19c4626,
    name: "qna"
  }, {
    path: "/services",
    component: _e9039c6a,
    name: "services"
  }, {
    path: "/sitepolicy",
    component: _6240db34,
    name: "sitepolicy"
  }, {
    path: "/sustainability",
    component: _4c6f6a54,
    name: "sustainability"
  }, {
    path: "/teammembers",
    component: _7a848725,
    name: "teammembers"
  }, {
    path: "/about/companyInfo",
    component: _2b59884f,
    name: "about-companyInfo"
  }, {
    path: "/about/create",
    component: _0979bda8,
    name: "about-create"
  }, {
    path: "/contacts/create",
    component: _040fe73e,
    name: "contacts-create"
  }, {
    path: "/investorinfo/create",
    component: _ce85052a,
    name: "investorinfo-create"
  }, {
    path: "/jobpost/create",
    component: _570c74d8,
    name: "jobpost-create"
  }, {
    path: "/news/create",
    component: _9686d884,
    name: "news-create"
  }, {
    path: "/qna/create",
    component: _76be0b71,
    name: "qna-create"
  }, {
    path: "/services/create",
    component: _0bfc5253,
    name: "services-create"
  }, {
    path: "/sustainability/create",
    component: _69322aea,
    name: "sustainability-create"
  }, {
    path: "/teammembers/create",
    component: _047eaf8e,
    name: "teammembers-create"
  }, {
    path: "/about/delete/:id?",
    component: _121085fc,
    name: "about-delete-id"
  }, {
    path: "/about/edit/:id?",
    component: _75964b61,
    name: "about-edit-id"
  }, {
    path: "/contacts/delete/:id?",
    component: _00932b98,
    name: "contacts-delete-id"
  }, {
    path: "/contacts/edit/:id?",
    component: _23486777,
    name: "contacts-edit-id"
  }, {
    path: "/investorinfo/delete/:id?",
    component: _8a003976,
    name: "investorinfo-delete-id"
  }, {
    path: "/investorinfo/edit/:id?",
    component: _42e92664,
    name: "investorinfo-edit-id"
  }, {
    path: "/jobpost/delete/:id?",
    component: _40fb5c32,
    name: "jobpost-delete-id"
  }, {
    path: "/jobpost/edit/:id?",
    component: _ad5012de,
    name: "jobpost-edit-id"
  }, {
    path: "/news/delete/:id?",
    component: _0bd4cfd0,
    name: "news-delete-id"
  }, {
    path: "/news/edit/:id?",
    component: _5b7bf3f7,
    name: "news-edit-id"
  }, {
    path: "/qna/delete/:id?",
    component: _6a5dfe4b,
    name: "qna-delete-id"
  }, {
    path: "/qna/edit/:id?",
    component: _ba036a2c,
    name: "qna-edit-id"
  }, {
    path: "/services/delete/:id?",
    component: _177a042d,
    name: "services-delete-id"
  }, {
    path: "/services/edit/:id?",
    component: _3c733d68,
    name: "services-edit-id"
  }, {
    path: "/sustainability/delete/:id?",
    component: _83588d78,
    name: "sustainability-delete-id"
  }, {
    path: "/sustainability/edit/:id?",
    component: _6e2f1fba,
    name: "sustainability-edit-id"
  }, {
    path: "/teammembers/delete/:id?",
    component: _41b4f713,
    name: "teammembers-delete-id"
  }, {
    path: "/teammembers/edit/:id?",
    component: _73ccd5b2,
    name: "teammembers-edit-id"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
